<template>
  <div class="form-group">
    <select class="form-control" v-model="selectedItem" @change="handleSelect">
      <option v-for="(item, index) in options" :key="index" :value="item[val]">
        {{ item[name] }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: ["options", "name", "val", "model", "selected"],
  data: () => ({
    selectedItem: null
  }),
  mounted() {
    if (this.selected) {
      this.selectedItem = this.selected;
    }
  },
  methods: {
    handleSelect() {
      this.$emit("update:model", this.selectedItem);
    }
  }
};
</script>

<style></style>

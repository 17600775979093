<template>
  <div class="container">
    <div class="mt-7">
      <h1 class="display-4">User Profile</h1>
      <modal :show.sync="modals.change_password">
        <h5 slot="header" class="modal-title" id="modal-title-default">
          Change Password
        </h5>

        <div>
          <validation-observer ref="ChangePasswordObserver">
            <base-alert v-if="success.change_password" type="success"
              >Password changed successfully</base-alert
            >
            <base-alert type="danger" v-if="errors.change_password">
              {{ errors.change_password }}</base-alert
            >
            <form @submit.prevent="changePassword()">
              <div class="form-group">
                <validation-provider
                  name="Old Password"
                  rules="required|min:6"
                  v-slot="{ errors }"
                >
                  <base-input
                    v-model="change_password.old_password"
                    type="password"
                    placeholder="Old Password"
                    :error="errors[0]"
                  ></base-input>
                </validation-provider>
              </div>
              <div class="form-group">
                <validation-provider
                  name="New Password"
                  rules="required|min:6"
                  v-slot="{ errors }"
                >
                  <base-input
                    v-model="change_password.new_password"
                    type="password"
                    placeholder="New Password"
                    :error="errors[0]"
                  ></base-input>
                </validation-provider>
              </div>
              <div class="form-group">
                <validation-provider
                  name="Confirm Password"
                  rules="required|min:6|password:@New Password"
                  v-slot="{ errors }"
                >
                  <base-input
                    v-model="change_password.confirm_password"
                    type="password"
                    placeholder="Confirm Password"
                    :error="errors[0]"
                  ></base-input>
                </validation-provider>
              </div>
            </form>
          </validation-observer>
        </div>

        <template slot="footer">
          <base-button class="ml-auto" @click="changePassword()" type="primary"
            >Save changes</base-button
          >
          <base-button
            type="default"
            outline
            @click="
              modals.change_password = false;
              change_password = {};
              $refs.ChangePasswordObserver.reset();
            "
            >Close
          </base-button>
        </template>
      </modal>
      <div class="py-4">
        <div class="row">
          <div class="col-sm-8">
            <card class="card-blog">
              <template slot="body">
                <!-- <h5 class="card-title">Edit Profile</h5> -->

                <div v-if="profile">
                  <validation-observer ref="updateProfile">
                    <form @submit.prevent="updateProfile()">
                      <base-alert v-if="success.update_profile" type="success"
                        >Profile Updated</base-alert
                      >
                      <base-alert type="danger" v-if="errors.update_profile">
                        Something went wrong, Could not update
                        profile</base-alert
                      >
                      <div class="form-group">
                        <label for="fullName">Full Name</label>
                        <validation-provider
                          name="Full Name"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <base-input
                            id="fullName"
                            v-model="profile.full_name"
                            type="text"
                            placeholder="Full Name"
                            :error="errors[0]"
                          ></base-input>
                        </validation-provider>
                      </div>
                      <div class="form-group">
                        <label for="email">Email</label>
                        <validation-provider
                          name="Email"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <base-input
                            id="email"
                            disabled
                            v-model="profile.email"
                            type="email"
                            placeholder="Email"
                            :error="errors[0]"
                          ></base-input>
                        </validation-provider>
                      </div>
                      <div class="form-group">
                        <label for="profession">Profession</label>
                        <validation-provider
                          name="Profession"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <base-select
                            :options="options.profession"
                            name="text"
                            val="id"
                            :model.sync="profile.profession"
                            :selected="profile.profession"
                          ></base-select>
                          <small v-if="errors[0]" class="text-danger"
                            >This field is required</small
                          >
                        </validation-provider>
                      </div>
                      <div class="form-group">
                        <label for="profession">Marketing Source</label>
                        <validation-provider
                          name="Marketing Source"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <base-select
                            :options="options.marketing_source"
                            name="text"
                            val="id"
                            :model.sync="profile.marketing_source"
                            :selected="profile.marketing_source"
                          ></base-select>
                          <small v-if="errors[0]" class="text-danger"
                            >This field is required</small
                          >
                        </validation-provider>
                      </div>
                      <input type="submit" hidden />
                      <p>
                        <strong
                          >Joined
                          {{ formatDate(profile.registration_date) }}</strong
                        >
                      </p>
                      <base-button @click="updateProfile" type="primary">
                        <span v-if="!loadings.update_profile">Update</span>
                        <spinner
                          v-else
                          class="spinner-grow-sm"
                          type="grow"
                        ></spinner
                      ></base-button>
                    </form>
                  </validation-observer>
                </div>
                <div
                  class="text-center"
                  style="margin-top: 3rem; margin-bottom: 3rem"
                  v-else
                >
                  <spinner />
                </div>
              </template>
            </card>
          </div>
          <div class="col-sm-4">
            <div>
              <base-button
                block
                class="mb-2"
                @click="modals.change_password = !modals.change_password"
                type="warning"
                >Change Password <i class="fa fa-cog"></i
              ></base-button>
              <base-button
                block
                class="mb-2"
                @click="$router.push('/history')"
                type="success"
                >History <i class="fa fa-history"></i
              ></base-button>
              <base-button
                block
                class="mb-2"
                @click="$store.dispatch('logout')"
                type="default"
                >Logout <i class="fa fa-sign-out"></i
              ></base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Moment from "moment";
import Modal from "@/components/Modal";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Spinner from "@/components/Spinner";
import BaseSelect from "@/components/BaseSelect";

export default {
  components: {
    Modal,
    ValidationProvider,
    ValidationObserver,
    Spinner,
    BaseSelect,
  },
  data: () => ({
    profile: null,
    modals: {
      change_password: false,
    },
    change_password: {},
    success: {
      change_password: false,
      update_profile: false,
    },
    errors: {
      change_password: null,
      update_profile: null,
    },
    loadings: {
      button: false,
      professions: true,
      marketing_source: true,
      update_profile: false,
    },
    options: {
      marketing_source: null,
      professions: null,
    },
  }),
  metaInfo: {
    title: "SherlockIT",
  },
  beforeMount() {
    this.updateOptions();
  },
  mounted() {
    this.getProfile();
    this.updateOptions();
  },
  methods: {
    updateOptions() {
      this.$axios.get("profession/").then((res) => {
        this.options.profession = res.data;
        this.loadings.profession = false;
      });
      this.$axios.get("marketing-source/").then((res) => {
        this.options.marketing_source = res.data;
        this.loadings.marketing_source = false;
      });
    },
    dateFromNow(date) {
      return Moment(date).fromNow();
    },
    formatDate(date) {
      return Moment(date).format("ll");
    },
    getProfile() {
      this.$axios.get("profile/").then((res) => {
        let profileData = { ...res.data };
        if(profileData.profession){
          profileData.profession = profileData.profession.id;
        }
        if (profileData.marketing_source) {
          profileData.marketing_source = profileData.marketing_source.id;
        }
        this.profile = profileData;
      });
    },
    cancel() {
      this.getProfile();
    },
    changePassword() {
      this.errors.change_password = false;
      this.$refs.ChangePasswordObserver.validate().then((success) => {
        if (!success) {
          return;
        }
        this.$axios
          .post("/change-password/", this.change_password)
          .then((res) => {
            if (res.status == 200) {
              this.change_password = {};
              this.$refs.ChangePasswordObserver.reset();
              this.success.change_password = true;
              setTimeout(() => {
                this.success.change_password = false;
              }, 3000);
            }
          })
          .catch((err) => {
            if (err.response.status == 400) {
              this.errors.change_password = err.response.data.detail;
            }
          });
      });
    },
    async updateProfile() {
      this.success.update_profile = false;
      this.errors.update_profile = false;
      this.loadings.update_profile = true;
      await this.$axios
        .post("/profile/", this.profile)
        .then((res) => {
          if (res) {
            this.success.update_profile = true;
            setTimeout(() => {
              this.success.update_profile = false;
            }, 3000);
          }
        })
        .catch((err) => {
          if (err) {
            this.errors.update_profile = true;
          }
        });
      this.loadings.update_profile = false;
    },
  },
};
</script>
<style scoped>
.underline--magical {
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 88%;
  transition: background-size 0.25s ease-in;
}
.underline--magical:hover {
  background-size: 100% 88%;
}
.joined-date {
  color: gray;
}
</style>
